<template>
  <div>
    <v-row
      class="spacer mb-6"
      no-gutters
    >
      <v-col cols="2">
        <p class="text-left mb-0">
          <span class="font-weight-bold mr-1">Free workers</span> {{ freeWorkers.free_cloud_workers_count }} of {{ freeWorkers.all_cloud_workers_count }}
        </p>
        <p class="text-left mb-0">
          <span class="font-weight-bold mr-1">Free proxies</span> {{ freeProxies.free_proxies_count }} of {{ freeProxies.all_proxies_count }}
        </p>
        <p class="text-left mb-0">
          <span class="font-weight-bold mr-1">Used apollo credits</span>
          {{ apolloCredits.apollo_used_credits }} of  {{ apolloCredits.apollo_total_credits }}
        </p>
      </v-col>
      <v-col>
        <v-btn
          depressed
          color="error"
          class="mb-4"
          @click="updateWorkers('inactive')"
        >
          Turn off all workers
        </v-btn>
        <v-btn
          depressed
          color="success"
          class="mb-4 ml-2"
          @click="updateWorkers('active')"
        >
          Turn on all workers
        </v-btn>
        <v-btn
          :color="isApollo? 'success': 'gray'"
          :disabled="loading"
          class="mb-4 ml-2"
          @click="isApollo = !isApollo"
        >
          Show apollo only
        </v-btn>
      </v-col>
    </v-row>
    <v-expansion-panels multiple>
      <workers-list
        v-for="(workersList, workerName) in workersMappedList"
        :key="workerName"
        class="mb-5"
        :workers-list="workersList"
      />
    </v-expansion-panels>
    <v-progress-linear
      v-if="loading"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    />
  </div>
</template>

<script>
  import WorkersList from '@/components/workersPage/WorkersList';
  import { mapGetters } from 'vuex';
  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';
  import { updateWorker, getFreeWorkers } from '@/api/workersMethods';
  import { getApolloCredits } from '@/api/apolloMethods';
  import { getFreeProxies } from '@/api/proxiesMethods';

  export default {
    name: 'WorkersPage',
    components: {
      WorkersList,
    },
    mixins: [lazyLoadMixin],
    data () {
      return {
        freeWorkers: {
          free_cloud_workers_count: 0,
          all_cloud_workers_count: 0,
        },
        freeProxies: {
          all_proxies_count: 0,
          free_proxies_count: 0,
        },
        apolloCredits: {
          apollo_total_credits: 0,
          apollo_used_credits: 0,
        },
        isApollo: false,
      };
    },
    computed: {
      ...mapGetters({
        workers: 'workers/workersList',
      }),
      workersMappedList () {
        const results = {};

        for (const worker of this.workers) {
          const deviceId = worker.device_id.length === 36 ? worker.device_id : worker.device_id.slice(0, 36);

          if (!results[deviceId]) {
            results[deviceId] = [];
          }
          results[deviceId].push(worker);
        }
        return results;
      },
    },
    watch: {
      async isApollo () {
        this.$store.commit('workers/SET_WORKERS_LIST', []);
        this.loading = true;
        this.canLoadMore = true;
        await this.$store.dispatch('workers/LOAD_WORKERS_LIST', { offset: 0, limit: 100, isAddCreds: true, apollo: this.isApollo });
        this.loading = false;
      },
    },
    async mounted () {
      this.freeWorkers = await getFreeWorkers();
      this.freeProxies = await getFreeProxies();
      this.apolloCredits = await getApolloCredits();
    },
    methods: {
      async handleScroll () {
        const bottomOfWindow =
          document.documentElement.scrollTop.toFixed() + window.innerHeight.toFixed() >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          this.loading = true;
          const count = this.workers.length;
          const props = {
            offset: this.workers.length,
            limit: 100,
            isAddCreds: true,
            apollo: this.isApollo,
          };
          await this.$store.dispatch('workers/LOAD_WORKERS_LIST', props);
          this.canLoadMore = this.workers.length > count;
          this.loading = false;
        }
      },
      async updateWorkers (status) {
        const workers = await updateWorker('', true, status);
        await this.$store.dispatch('workers/SET_WORKERS_LIST', workers);
      },
    },
  };
</script>

<style scoped>

</style>
